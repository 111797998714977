import React from 'react'
import Layout from '../components/layout'
import ProductPhotography from '../components/categories/product-photography'
import Seo from '../components/seo'

const ProductPhotographyPage = ({ location }) => {
  return (
    <Layout location={location}>
      <ProductPhotography />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Product Photography Freelancers | Codestaff'
  const description =
    'Hire the best Product Photography freelancers at Codestaff. Get the top 1% of Product Photography professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default ProductPhotographyPage
